.add-to-list-modal {
    width: 40vw;
    max-height: calc(100vh - 2rem);
    overflow-y: auto;
    background-color: #456;
    /* padding: 16px 30px 16px 30px; */
    border-radius: 3px;
    display: flex;
}

.add-to-list-modal .list-modal-content {
    width: 100%;
}

.add-to-list-modal .header{
    color: white;
    font-size: 1.38461538rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    border-bottom: 1px solid #345;
    padding-bottom: 16px;
    padding: 16px 30px 16px 30px;
    display: flex;
    justify-content: space-between;
}

.add-to-list-modal .header button {
    font-size: 18px;
    background-color: transparent;
    color: #9ab;
    border: none;
    cursor: pointer;
}

.add-to-list-modal .header button:hover {
    color: white;
}

.add-to-list-modal .actions {
    padding: 16px 30px 16px 30px;
    /* border-bottom: 1px solid #345; */
}

/* .add-to-list-modal .lists {
    padding: 16px 30px 16px 30px;
} */

.add-to-list-modal .lists {
    padding-bottom: 16px;
    border-bottom: 1px solid #345;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.actions .new-list span {
    height: 100%;
    background-color: transparent;
    color: #bcd;
    cursor: pointer;
    border: none;
    font-size: 1.15384615rem;
    line-height: 1.26666667;
}

.actions .new-list span:hover {
    color: white;
}

/* .actions .search {

} */

.actions .search input {
    font-size: 1.15384615rem;
    line-height: 1.26666667;
    background: transparent;
    border: none;
    outline: none;
    text-align: right;
    color: #bcd;
}

/* .actions .search label::after {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -440px -30px;
    background-repeat: no-repeat;
    content: "";
    height: 14px;
    margin-top: -7px;
    width: 14px;
} */

.lists .list {
    height: 100%;
    background-color: transparent;
    color: #bcd;
    /* cursor: pointer; */
    border: none;
    font-size: 1.15384615rem;
    line-height: 1.26666667;
    padding: 8px 30px 8px 30px;
    border-top: 1px solid #345;
    display: flex;
    justify-content: space-between;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lists .selected {
    color: white;
}

.lists .list .list-names {
    cursor: pointer;
}

.lists .list .list-names:hover {
    color: white;
}

.lists .list .number-of-movies {
    color: #9ab;
    flex-shrink: 0;
    font-size: 1rem;
    line-height: 1.53846154;
    /* padding-left: 15px; */
    /* text-align: right; */
    white-space: nowrap;
}

.add-to-list-modal .submit {
    background-color: #345;
    display: flex;
    justify-content: flex-end;
    /* margin-top: auto; */
    padding: 16px 30px 16px 30px;
}

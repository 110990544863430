.footer-box {
    background-color: #2c3440;
    padding: 32px 0;
    height: 125px;
    z-index: 10;
    position: relative;
    margin-bottom: 0;
    margin-top: 12vh;
}

.about-links {
    display: flex;
    align-items: center;
    color: #9ab;
    font-size: 15px;
    font-weight: 630;
    letter-spacing: .07692308em;
    justify-content: center;
}

.about-links a{
    color: #9ab;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 5px;
    text-decoration: none;
}

.about-links a:hover {
    color: white;
}

.about {
    padding: 0 5px;
}

.profile-settings-header {
    margin: 0;
    font-weight: 400;
    margin-bottom: 25px;
    color: #abc;
    font-size: 2rem;
    line-height: 1.23076923;
}

.profile-settings-navbar {
    margin-bottom: 40px;
    border-bottom: 1px solid #456;
    color: #678;
    font-size: 11px;
    line-height: 1;
    min-height: 30px;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.profile-settings-navbar-list {
    padding: 0;
    display: flex;
}

.profile-settings-navbar-list-item {
    /* float: left; */
    font-size: 1rem;
    letter-spacing: .075em;
    line-height: 1;
    margin: 0 15px 0 0;
    text-transform: uppercase;
    color: #00e054;
    padding: 0 0 5px;
}

.profile-settings-navbar-list-item .selected {
    border-bottom: 1px solid #fff;
    color: #fff;
}





.profile-settings-change-fields {
    display: flex;
    justify-content: space-between;
}

.personal-settings {
    width: 41%;
}

.personal-settings label {
    color: white;
    margin-bottom: 0.38461538rem
}

.profile-settings-name-inputs {
    display: flex;
    justify-content: space-between;
}

.profile-settings-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.profile-settings-input {
    height: 30px;
    background-color: #2c3440;
    font-size: 16px;
    line-height: 17px;
}

.name-input {
    width: 49%;
}

.favorite-films {
    width: 49.5%;
    margin-bottom: 15px;
}

.favorite-films-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.38461538rem
}

.favorite-films-label {
    color: white;
    font-size: 1.15384615rem;
    line-height: 1.2;
    font-weight: 400;
}

.favorite-films-instructions {
    margin: 0;
}

.edit-favorite-films-poster-list {
    margin-right: -10px;
    height: 165px;
    overflow: visible;
    position: relative;
    z-index: 0;
    padding: 0;
}

.edit-favorite-films-poster-list-placeholder {
    height: 165px;
    overflow: visible;
    display: block;
    float: left;
    margin-right: 10px;
    width: calc(25% - 10px);
    position: relative;
    background-color: #2c3641;
    border-color: #456;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px rgba(221,238,255,.25);
    border-radius: 4px;
}

.edit-profile-buttons {
    width: 41%;
    justify-content: space-between;
    padding-top: 0.76923077rem;
    margin-bottom: 20px;
}

#change-password-button {
    font-size: 100%;
}

#change-password-button span {
    font-size: .84615385rem;
    font-weight: 400;
    letter-spacing: .075em;
    line-height: 12px;
    padding: 9px 12px 8px;
 }

.navbar-box.transparent {
    background-color: transparent;
}

.single-movie-backdrop {
    box-sizing: border-box;
    width: 100%;
    height: 675px;
    top: 0;
    z-index: 0;
    position: absolute;
    background-color: #14181c;
}

.backdrop-images-holder {
    left: 3%;
    right: 3%;
    box-sizing: border-box;
    height: 675px;
    top: 0;
    z-index: 0;
    position: absolute;
}

.backdrop-images-holder img.backdrop-image-placeholder {
    image-rendering: pixelated;
}
.backdrop-image-placeholder {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    /* image-rendering: pixelated; */
    opacity: .35;
    transition-property: opacity;
    transition-duration: 0s;
    transition-delay: 4s;
    top: 0;
    z-index: 0;
    position: absolute;
}

.backdrop-image {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
    transition-property: opacity;
    transition-duration: .75s;
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
    top: 0;
    z-index: 0;
    position: absolute;
}

.backdrop-fade {
    position: relative;
    top: 0;
    height: 500px;
    width: 100%;
    z-index: 0;
}

.backdrop-fade:before {
    background-image:
    linear-gradient(90deg,#14181d 0,rgba(20,24,29,.986)
     .97%,rgba(20,24,29,.945)
      2.07833333%,rgba(20,24,29,.883)
       3.29666667%,rgba(20,24,29,.803)
        4.60166667%,rgba(20,24,29,.711)
         5.96666667%,rgba(20,24,29,.61)
          7.365%,rgba(20,24,29,.504)
           8.77166667%,rgba(20,24,29,.398)
            10.16%,rgba(20,24,29,.296)
            11.505%,rgba(20,24,29,.203)
             12.78%,rgba(20,24,29,.122)
              13.95833333%,rgba(20,24,29,.059)
               15.01666667%,rgba(20,24,29,.016)
                15.92833333%,rgba(20,24,29,0)
                 16.66666667%,rgba(20,24,29,0)
                  83.33333333%,rgba(20,24,29,.016)
                   84.07166667%,rgba(20,24,29,.059)
                    84.98333333%,rgba(20,24,29,.122)
                     86.04166667%,rgba(20,24,29,.203)
                      87.22%,rgba(20,24,29,.296)
                       88.495%,rgba(20,24,29,.398)
                        89.84%,rgba(20,24,29,.504)
                         91.22833333%,rgba(20,24,29,.61)
                          92.635%,rgba(20,24,29,.711)
                           94.03333333%,rgba(20,24,29,.803)
                            95.39833333%,rgba(20,24,29,.883)
                             96.70333333%,rgba(20,24,29,.945)
                              97.92166667%,rgba(20,24,29,.986)
                               99.03%,#14181d),
    linear-gradient(0deg,#14181d 0,#14181d 21.48148148%,rgba(20,24,29,.986)
     23.63703704%,rgba(20,24,29,.945)
      26.1%,rgba(20,24,29,.883)
       28.80740741%,rgba(20,24,29,.803)
        31.70740741%,rgba(20,24,29,.711)
         34.74074074%,rgba(20,24,29,.61)
          37.84814815%,rgba(20,24,29,.504)
           40.97407407%,rgba(20,24,29,.398)
            44.05925926%,rgba(20,24,29,.296)
             47.04814815%,rgba(20,24,29,.203)
              49.88148148%,rgba(20,24,29,.122)
               52.5%,rgba(20,24,29,.059)
                54.85185185%,rgba(20,24,29,.016)
                 56.87777778%,rgba(20,24,29,0)
                  58.51851852%);
    content: "";
    display: block;
    height: 675px;
    /* left: 50%; */
    pointer-events: none;
    position: absolute;
    top: 0;
    /* transform: translateX(-50%); */
    width: 100%;
    z-index: 0;
}

.single-movie-page {
    padding-left: 12%;
    padding-right: 12%;
    color: #9ab;
    background-color: #14181c;
    background-image: none;
    padding-top: 400px;
    display: flex;
    /* position: relative;
    z-index: 1; */
}

.single-movie-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 50px;
    position: relative;
    z-index: 1;
}

.movie-interaction-statistics {

}

/* .movie-interaction-statistics .icon {
    background-image: url(../../images/cheddarboxd-icons.svg);
    width: 16px;
    height: 12px;
}

.movie-views {
    display: flex;
    vertical-align: top;
}

.movie-interaction-statistics .view-icon {
    background-position: -360px -30px;
    display: inline-block;

}

.movie-lists {
    display: flex;
    vertical-align: top;
}

.movie-interaction-statistics .list-icon {
    background-position: -420px -30px;
    display: inline-block;
}

.movie-likes {
    display: flex;
    vertical-align: middle;
}

.movie-interaction-statistics .like-icon {
    background-position: -380px -30px;
    display: inline-block;
} */

.single-movie-information {

}

.header-box {
    display: flex;
    margin-bottom: 30px;
    align-items: baseline;
}

.single-movie-information .title {
    color: white;
    font-size: 26px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin-right: 7px;
}

.header-information {
    display: flex;
    align-items: center;
}

.single-movie-information .release-date {
    font-size: 16px;
    color: #DDEEFF;
    margin-right: 7px;
}

.original-title {
    font-size: 16px;
    font-style: italic;
}

.information-box {
    display: flex;

}

.synopsis-box {
    width: 62%;
    font-size: 16px;
    height: 290px;
    overflow: hidden;
}

.interaction-sidebar-box {
    width: 38%;
    padding-left: 5%;
}

.interaction-sidebar {
    background-color: #456;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 10px; */
}

.interaction-actions {
    box-sizing: border-box;
    width: 100%;
}

.action-row {
    display: flex;
    border-bottom: 1px solid #2c3440;
    box-sizing: border-box;
    color: #bcd;
    padding: 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#top-icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.user-movie-status {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.watched-status {
    display: flex;
    flex-direction: column;
}

.icon-box {
    color: #bcd;
    cursor: pointer;
    display: block;
}

.icon-box:hover {
    color: white;
}

.action-icon {
    min-width: 50px;
    display: block;
    height: 100%;
    cursor: pointer;
}

.action-icon::before {
    background-size: 800px 1000px;
    height: 40px;
    width: 50px;
    content: "";
    display: block;
    margin: 0 auto 3px;
}

.action-icon .hover-text {
    display: none;
}

.action-icon.on:hover .original-text {
    display: none;
}

.action-icon.on:hover .hover-text {
    display: inline;
}

.action-icon.watch::before {
    background-position: -280px -850px;
}

.action-icon.watch.on::before {
    background-position: -280px -890px;
}

/* .action-icon.like.on:hover .original-text {
    display: none;
}

.action-icon.like.on:hover .hover-text {
    display: inline;
} */

.action-icon.like::before {
    background-position: -340px -850px;
}

.action-icon.like.on::before {
    background-position: -340px -890px;
}

/* .action-icon.watchlist.on:hover .original-text {
    display: none;
}

.action-icon.watchlist.on:hover .hover-text {
    display: inline;
} */

.action-icon.watchlist::before {
    background-position: -400px -850px;
}

.action-icon.watchlist.on::before {
    background-position: -400px -890px;
}

.action-row.rate {
    flex-direction: column;
    position: relative;
}

.rating-field {
    display: none;
    background: transparent;
    border: none;
    color: #2c3641;
    font-size: 10px;
    height: 10px;
    position: absolute;
}

.rate-movie-box {
    display: block;
    margin-top: 4px;
    /* width: 100%; */
    position: relative;
}

/* .remove-rating {
    display: none;
} */

.remove-rating {
    background: none;
    bottom: 10px;
    height: 32px;
    left: 5px;
    overflow: hidden;
    position: absolute;
    width: 20px;
    display: block;;
    text-indent: 110%;
    white-space: nowrap;
}

.remove-rating::after {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -370px -50px;
    content: "";
    display: block;
    height: 16px;
    right: 4px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: translateX(6px);
    transition: all .2s;
    width: 16px;
    cursor: pointer;
}

.remove-rating:hover::after {
    opacity: .6;
    transform: translateX(0);
}

.rate-movie-box:hover + .remove-rating::after {
    opacity: .6;
    transform: translateX(0);
}

.rating-stars {
    display: inline-block;
}

/* .star {
    display: inline-block;
    width: 180px;
    height: 32px;
    position: relative;
    cursor: pointer;
} */

/* .star-container.selected {
    background-position: 1px -925px;
} */

.rate-icon.selected,
.rate-icon.hover {
    background-position: 1px -925px;
}


/* .rate-icon.selected:hover {
    display: none;
} */

.rate-icon.hover  {
    background-position: 1px -960px;
}

.rate-icon {
    display: inline-block;
    width: 36px;
    height: 32px;
    background-position: 1px -890px;
  }

/* .rate-icon.hover:hover {
    background-position: 1px -960px;
}

.rate-icon.hover {
    display: none;
} */

.action-row button {
    height: 100%;
    background-color: transparent;
    color: #bcd;
    cursor: pointer;
    border: none;
    text-align: center;
}

.action-row button:hover {
    color: white;
}

/* #not-signed-in-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.reviews-box {

}

.reviews-header {
    font-size: 18px;
    margin-bottom: 10px;
    border-bottom: 1px solid #456;
}

.review-content {

}

.single-review-box {
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #456;
    max-height: 200px;
}

.reviewer-image {
    width: 8%;
    display: flex;
    align-items: start;
    /* justify-content: center; */
    margin-right: 12px;
    max-width: fit-content;
}

#reviewer-icon {

}

.review-imformation {

}

.review-stats {
    display: flex;
    padding-bottom: 10px;
}

.reviewer-name {
    padding-right: 10px;
}

.reviewer-name span{
    font-weight: 600;
}

.review-rating::before {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -450px -50px;
    height: 16px;
    /* width: 36px; */
    content: "";
    display: block;
}

.review-information .rating::before {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -450px -50px;
    height: 16px;
    /* width: 36px; */
    content: "";
    display: block;
}

.review-information .like {
    margin-right: 7px;
}

.review-information .like.off::before {
    display: none;
}

.review-information .like::before {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -390px -90px;
    height: 16px;
    width: 15px;
    content: "";
    display: block;
}

.review-words {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 144px;
}

.profile-page-box {
    margin-left: 12%;
    margin-right: 12%;
    color: #9ab;
    padding-top: 30px;
}

.profile-page-header {
    margin-bottom: 3.07692308rem;
    overflow: hidden;
}

.profile-information {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.profile-header-left {
    display: flex;
}

.profile-image-labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
}

.profile-image-labels .edit-profile-button {
    text-align: center;
    font-size: 13px;
    background: #567;
    border: none;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.2);
    color: #c8d4e0;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    padding: 7px 10px 6px;
    letter-spacing: .075em;
    line-height: 12px;
    margin: 0;
    padding: 9px 12px 8px;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.profile-username {
    margin-bottom: 5px;
    max-width: 450px;
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    overflow: hidden;
    padding: 3px 0;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    /* font-size: 24px; */
    color: white;
}

.profile-header-right {
    display: flex;
    align-items: center;
}

.profile-statistics {
    display: flex;
    align-items: center;
}

.stats-count {
    background-image: linear-gradient(90deg,#24303c 50%,rgba(36,48,60,0) 0);
    background-position: 0.76923077rem 0;
    background-repeat: repeat-y;
    background-size: 2px 1px;
    padding-left: 1.53846154rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-stat-number {
    align-items: center;
    color: #d8e0e8;
    font-size: 22px;
    font-weight: 400;
}

.profile-stat-word {
    color: #678;
    display: block;
    font-size: .76923077rem;
    font-weight: 400;
    letter-spacing: .075em;
    margin-top: 0.75em;
    text-transform: uppercase
}

.profile-navbar {
    border: 1px solid #24303c;
    border-radius: 3px;
}

.profile-navbar-list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.profile-navbar-list .navitem {
    font-size: inherit;
    line-height: 1.38461538;
}

/* .profile-navbar-list .profile-link {
    color: #fff;
    text-decoration: underline #00e054;
} */

.profile-navbar-list-item {
    color: #9ab;
    display: block;
    font-size: 1.15384615rem;
    line-height: 1.33333333;
    padding: 12px 0.96153846rem;
    position: relative;
}

.profile-navbar-list .profile-link {
    color: #fff;
    text-decoration: underline #00e054;
}

/* .profile-navbar-list-item .selected {
    color: #fff;
    text-decoration: underline #00e054;
} */

.profile-navbar-list-item:hover {
    color: #40bcf4;
}

.profile-page-body {
    display: flex;
    justify-content: space-between;
}

.left-side {
    width: 66%;
}

.profile-body-header {
    border-bottom: 1px solid #456;
    color: #9ab;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .075em;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.profile-body-section {
    padding-bottom: 2.30769231rem;
    width: 100%;
}

.favorite-films-content {
    margin-bottom: 0.70588235em;
    font-size: 1.15384615rem;
    word-break: break-word;
    line-height: 1.5;
}

.favorite-films-poster-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding: 0;
    /* margin: 0; */
}

.favorite-films-poster-list-placeholder {
    height: 225px;
    width: 150px;
    display: block;
    margin-left: 10px;
    background-color: #111;
    box-shadow: inset 0 0 0 1px rgba(221,238,255,.25);
    border-radius: 4px;
    /* display: inline-block; */
    overflow: hidden;
    position: relative;
}

.right-side {
    width: 27%;
}

.profile-recent-reviews {
    width: 100%;
}

.profile-recent-reviews .information-box {
    border-top: 1px solid #456;
    padding-bottom: 15px;
    width: 100%;
}

.profile-recent-reviews .information {
    display: flex;
    margin-top: 0.76923077rem;
    max-height: 111.6px;
    width: 100%;
}

.profile-recent-reviews .poster-box {
    margin-right: 20px;
    /* position: relative; */
    width: 12%;
}

.profile-recent-reviews .poster {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

/* .profile-recent-reviews .poster-frame {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    box-shadow: inset 0 0 1px #def;
    border-radius: 3px;
} */

.profile-recent-reviews .review-content {
    width: 88%;
}

.profile-recent-reviews .title-release-date {
    font-family: TiemposTextWeb-Semibold,Georgia,serif;
    font-size: 1.69230769rem;
    font-weight: 400;
    margin: 0 0 10px
}

.profile-recent-reviews .title {
    color: #fff;
    margin-right: 5px;
}

.profile-recent-reviews .release-date {
    font-size: 1.38461538rem;
    color: #89a;
    font-family: Graphik-Light-Web,sans-serif;
    font-weight: 400;
}

.profile-recent-reviews .rating-creation-date {
    margin-bottom: 10px;
    color: #678;
    display: flex;
}

.profile-recent-reviews .rating {
    /* background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -476px -50px; */
    /* height: 16px; */
    margin-right: 7px;
    top: -1px;
    /* width: 36px; */
}

.profile-recent-reviews .rating::before {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -450px -50px;
    height: 16px;
    /* width: 36px; */
    content: "";
    display: block;
}

.profile-recent-reviews .like {
    margin-right: 7px;
}

.profile-recent-reviews .like.off::before {
    display: none;
}

.profile-recent-reviews .like::before {
    background-image: url(../../images/cheddarboxd-icons.svg);
    background-position: -390px -90px;
    height: 16px;
    width: 15px;
    content: "";
    display: block;
}

.profile-recent-reviews .creation-date {

}

.profile-recent-reviews .review {
    overflow: hidden;
    max-height: calc(111.6px - 25px - 16px - 20px);
}

.watchlist-box {
    margin-top: 0.76923077rem;;
}

.watchlist-box .list-images {
    height: 105px;
}

.watchlist-box .list-images-image {
    width: 29%;
    border-radius: 3px;
}

.watchlist-box ._1 {
    margin-left: 17.75%;
}

.watchlist-box ._2 {
    margin-left: 35.5%;
}

.watchlist-box ._3 {
    margin-left: 53.25%;
}

.watchlist-box ._4 {
    margin-left: 71%;
}

.single-list-page {
    padding-left: 12%;
    padding-right: 12%;
    color: #9ab;
    background-color: #14181c;
    background-image: none;
    padding-top: 400px;
    display: flex;
    /* position: relative;
    z-index: 1; */
}

.list-information {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    width: 75%;
}

.list-creator-information {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #456;
    width: 100%;
}

.list-creator-image {
    margin-right: 5px;
}

.list-creator-name {

}

.list-content {

}

.list-name {

}

.list-description {
    margin-bottom: 10px;
    font-size: 16px;
}

.list-movies {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    grid-auto-rows: minmax(min-content, max-content);
    grid-auto-flow: row dense;
}

.list-movie-image-box {
    /* height: 187px; */
    width: 100%;
    height: 187px;
    position: relative;
}

.list-movie-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.list-interaction {
    width: 25%;
    z-index: 1;
    padding-left: 5%;
}

.edit-list-link {
    background-color: #456;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #9ab
}

.edit-list-link:hover {
    color: white;
}

.list-sidebar-button {
    background-color: #456;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #9ab
}

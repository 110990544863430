/* TODO Add site wide styles */
ul {
    list-style: none;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

nav {
    display: block;
}

html {
    font-size: 13px;
    height: 100%;
}

h2 {
    margin: 18px 0 12px;
}

body {
    margin: 0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /* background-color: #2c3440; */
    background-color: #14181c;
    /* background: #14181c; */
    background: linear-gradient(180deg, #2c3440, #14181c);
    background-attachment: fixed;
    /* background-image: linear-gradient(180deg,#14181c 0,#14181c 250px,#2c3440 251px); */
    font-size: 13px;
    color: #9ab;
    /* min-height: 100vh; */
    height: 100%;
}

.content {
    margin-left: 12%;
    margin-right: 12%;
    color: #9ab;
    padding-top: 30px;
}

/* #root {
    background-color: #2c3440;
    background: linear-gradient(180deg,#14181c 0,#14181c 250px,#2c3440 251px);
} */

a:link {
    text-decoration: none;
    /* color: #445566;
    color: #EE7000;
    color: #00AC20; */
}

input {
    background-color: #CCDDEE;
    border: none;
    outline: none;
    border-radius: 3px;
}

textarea {
    background-color: #CCDDEE;
    border: none;
    border-radius: 3px;
}

input:active, input:focus {
    background-color: white;
    border: none;
    color: black;
  }

textarea:active, textarea:focus {
    background-color: white;
    border: none;
    color: black;
  }

  .buttons-box {
    display: flex;
    align-items: center;
}

.green-button {
    height: 29px;
    background-color: #00ac1c;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 30%);
    color: #f4fcf0;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
}

.green-button:hover {
    background-color: #009d1a;
    /* color: white; */
}

.grey-button {
    height: 29px;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 30%);
    color: #c8d4e0;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #567;
    display: flex;
}

.grey-button:hover {
    background-color: #678;
    color: #fff;
}

.green-button span {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .075em;
    line-height: 12px;
    margin: 0;
    padding: 9px 12px 8px;
    text-decoration: none;
    text-transform: uppercase;
}

.grey-button span {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .075em;
    line-height: 12px;
    margin: 0;
    padding: 9px 12px 8px;
    text-decoration: none;
    text-transform: uppercase;
}

/* icon universal styles */
.action-icon::before {
    background-image: url(./images/cheddarboxd-icons.svg);
}

.rate-icon {
    background-image: url(./images/cheddarboxd-icons.svg);
}

.movie-interaction-statistics .icon {
    background-image: url(./images/cheddarboxd-icons.svg);
    width: 16px;
    height: 12px;
}

.movie-interaction-statistics .icon-number {
    display: flex;
    align-items: center;
    justify-content: center;
}

.movie-interaction-statistics .icon-number:hover {
    color: white;
}

.movie-interaction-statistics .view-icon {
    background-position: -360px -30px;
    display: inline-block;
}

.movie-interaction-statistics .list-icon {
    background-position: -420px -30px;
    display: inline-block;
}

.movie-interaction-statistics .like-icon {
    background-position: -380px -30px;
    display: inline-block;
}

.poster-box {
    position: relative;
}

.poster-frame {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 21;
    box-shadow: inset 0 0 1px #def;
    border-radius: 3px;
}

.poster-overlay {
    height: 100%;
    width: 100%;
    border-radius: 3px;
}

.poster-overlay.green:hover {
    border: 3px solid #00e054;
    transition: border .1s;
}

.poster-overlay.white:hover {
    border: 3px solid #def;
    transition: border .3s;
    /* z-index: 8; */
}

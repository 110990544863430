.navbar-box.solid {
    background-color: #14181c;
}

/* #home-page-movie-backdrop {
    height: 100vh;
} */

.create-account-home-page {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 250px;
}

.home-page-message {
    color: white;
    font-size: 28px;
    letter-spacing: .25px;
    word-spacing: .5px;
}

.create-account-home-page-button-box {
    display: flex;
    align-items: center;
    width: 20%;
}

.create-account-home-page-button-box button {
    height: 38px;
    background-color: #00b020;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 30%);
    color: #f4fcf0;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 12px;
    font-size: 20px;
    line-height: 21px;
    font-weight: 400;
}

.account-created-home-page {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 411px;
}

.welcome-message {
    color: white;
    font-size: 28px;
    letter-spacing: .25px;
    word-spacing: .5px;
}

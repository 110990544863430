/* .profile-dropdown {
  position: absolute;
} */

.hidden {
  display: none;
}

/* #hidden {
  display: none;
} */

/* .transparent {
  background-color: transparent;
} */

.navbar-box {
  /* background-color: #14181c; */
  height: 75px;
  margin-top: 0;
  z-index: 10;
  position: relative;
}


.navbar {
  /* background-color: transparent; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-left: 12%;
  margin-right: 12%;
  height: 72px;
}

.navbar-left-side {
  display: flex;
  /* margin-top: 22px; */
  height: 100%;
  width: 25%;
  align-items: center;
  justify-content: left;
  font-size: 25px;
  box-sizing: border-box;
}



.home-link {
  width: 100%;
  color: white;
}

.navbar-login-form {
  display: flex;
  justify-content: right;
  width: auto;
}

.navbar-right-side {
  display: flex;
  /* margin-top: 22px; */
  transition: right .3s cubic-bezier(.165,.84,.44,1);
  line-height: 17px;
  font-size: 16px;
  height: 100%;
  align-items: center;
  justify-content: right;
  width: 65%;
  /* height: 25px; */
}

.login-signup-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
}

.navbar-links {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.navbar-links button{
  display: flex;
  align-items: center;
  color: #9ab;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 630;
  letter-spacing: .07692308em;
  cursor: pointer;
}

.navbar-links button:hover{
  color: white;
}

#navbar-profile-icon {
  padding-right: 3px;
}

.dropdown-items {
  display: flex;
}

/* .dropdown-menu {
  background-color: #89a;
  border-radius: 4px;
  border-width: 1px;
  border-color: black;
}

.profile-dropdown-list {
  color: black;
}

.profile-dropdown-list > .navbar-links {
  color: white;
} */

.log-out-button-box {

}

.log-out-button-box button {
  position: absolute;
  background-color: #89a;
  cursor: pointer;
}

.profile-page-link {
  position: absolute;
  background-color: #89a;
  cursor: pointer;
  color: black;
}

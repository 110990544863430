.lists-browser-box {
    margin-left: 12%;
    margin-right: 12%;
    color: #9ab;
    padding-top: 40px;
}

.create-list-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.create-list-text {
    display: flex;
    align-items: center;
    color: #abc;
    font-size: 26px;
    margin-bottom: 20px;
}

.create-list-button-box {
    display: flex;
    align-items: center;
    width: 16%;
    height: 34px;
}

.create-list-button-box button {
    cursor: pointer;
    padding: 4px 5px;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #283038;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 5%);
    color: #9ab;
    border: none;
}

.create-list-button-box button:hover {
    background: #303840;
    color: #def;
}

.list-lists-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-auto-flow: row dense;
    gap: 40px;
    border-top: 1px solid #456;
    padding-top: 15px;
}

.single-list-card {
    display: flex;
    flex-direction: column;
}

.list-images {
    height: 200px;
    width: 100%;
    /* height: 100%; */
    display: flex;
    position: relative;
}

.list-images-image {
    object-fit: cover;
    width: 46%;
    height: 100%;
    border-radius: 4px;
    border-color: #def;
    /* border-width: 1px; */
    box-shadow: 2px 0 7px #000;
    position: absolute;
}

._0 {
    z-index: 20;
    margin-left: 0px;
}

._1 {
    z-index: 19;
    margin-left: 13.5%;
}

._2 {
    z-index: 18;
    margin-left: 27%;
}

._3 {
    z-index: 17;
    margin-left: 40.5%;
}

._4 {
    z-index: 16;
    margin-left: 54%;
}

.list-information {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.list-name {
    margin: 10px 0px 6px;
    font-size: 20px;
    color: white;
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-maker {
    color: #abc;
    display: flex;
    align-items: center;
}

#list-maker-icon {
    padding-right: 5px;
}

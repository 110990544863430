div {
    box-sizing: border-box;
}

.navbar-box.solid {
    background-color: #14181c;
}

.movies-browser-box {
    margin-left: 12%;
    margin-right: 12%;
    color: #9ab;
}

.movie-list-container {
    margin-top: 16px;
    /* padding-inline: 40px; */
    display: grid;
    grid-template-columns: repeat(var(--breakpoint-grid_columns, 1fr), minmax(0, 1fr));
    grid-auto-rows: minmax(min-content, max-content);
    grid-auto-flow: row dense;
    /* grid-template-columns: minmax(0, 1fr); */
    column-gap: 10px;
    row-gap: 10px;

}

@media only screen and (min-width: 1128px) {
    .movie-list-container {
        --breakpoint-grid_columns: 4;
    }
}

@media  only screen and (min-width: 950px) and (max-width: 1127px) {
    .movie-list-container {
        --breakpoint-grid_columns: 3;
    }
}

@media  only screen and (min-width: 550px) and (max-width: 949px) {
    .movie-list-container {
        --breakpoint-grid_columns: 2;
    }
}

@media  only screen and (max-width: 549px) {
    .movie-list-container {
        --breakpoint-grid_columns: 1;
    }
}

.single-movie-card {
    display: flex;
    flex-direction: column;
}

.movie-image-box {
    /* margin-bottom: 12px; */
    min-height: 1px;
    height: 350px;
}

.movie-image {
    object-fit: cover;
    width: 230px;
    height: 100%;
    border-radius: 4px;
}

.movie-interaction-statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    margin-left: 30%;
    margin-right: 30%;
    padding: 10px 0 5px;
}

.movie-views {

}

.views-number {

}

.movie-lists {

}

.movie-likes {

}
